import {handlebars} from 'hbs';
import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import {bindImageZoom, setCurrentOpenedImage} from '../../src/js/carousel';
import modalJSONData from './data.json';

let flickitySlide = {};

const mobileModalTemplate = '<div class="mob-mod">\n' +
    '    <div class="mob-mod__close-container">\n' +
    '        <svg class="mob-mod__close">\n' +
    '            <use href="#cross" xlink:href="#cross" />\n' +
    '        </svg>\n' +
    '    </div>\n' +
    '    <div class="mob-mod__content">\n' +
    '        <div class="dropdown">\n' +
    '            <div class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown">\n' +
    '                <div class="mob-mod__dropdown-toggle" >\n' +
    '                    <div class="mob-mod__dropdown-section">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="mob-mod__dropdown-icon-container">\n' +
    '                    <svg class="mob-mod__dropdown-icon">\n' +
    '                        <use href="#arrow-dropdown" xlink:href="#arrow-dropdown" />\n' +
    '                    </svg>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="dropdown-menu">\n' +
    '                {{#each sectionList}}\n' +
    '                    <div class="mob-mod__dropdown-item" data-sectionid="{{sectionId}}">\n' +
    '                        {{name}}\n' +
    '                    </div>\n' +
    '                {{/each}}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="mob-mod__carousel" id="mob-mod__carousel">\n' +
    '            {{#each sectionList}}\n' +
    '                {{#each imgList}}\n' +
    '                    <div class="mob-mod__carousel-cell">\n' +
    '                        <img class="mob-mod__carousel-image" data-sectionid="{{sectionId}}" data-name="{{name}}" data-flickity-lazyload="{{src}}">\n' +
    '                    </div>\n' +
    '                {{/each}}\n' +
    '            {{/each}}\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>';

export function handleOrderMobileClick() {
    const currentModal = document.querySelector('.mob-mod');

    if (!currentModal) {const sectionName = this.dataset.name;
        const renderFunction = handlebars.compile(mobileModalTemplate);
        const innerHTML = renderFunction({sectionList: modalJSONData.sectionList});
        const temp = document.createElement('div');
        temp.innerHTML = innerHTML;
        const modal = temp.firstElementChild;

        const close = temp.querySelector('.mob-mod__close');
        const dropdownItemList = temp.querySelectorAll('.mob-mod__dropdown-item');
        const currentActiveItem = temp.querySelector(`div[data-sectionid=${sectionName}]`);

        for (let dropdownItem of dropdownItemList) {
            dropdownItem.addEventListener('click', handleDropdownItemClick)
        }

        document.body.appendChild(modal);

        //Привет, Илюшкинс, про этот костыль я написал в компоненте modal
        if (!document.body.classList.contains('ov_h')) {
            document.body.classList.add('ov_h');
        }

        flickitySlide = new Flickity('.mob-mod__carousel', {
            wrapAround: true,
            freeScroll: false,
            draggable: true,
            lazyLoad: 1,
            resize: true,
            contain: true,
        });
        flickitySlide.on('change', handleFlickityOnChange);
        flickitySlide.focus();
        setCurrentSliderState(currentActiveItem, modal);

        const carousel = document.getElementById('mob-mod__carousel');
        bindImageZoom(carousel, modal);

        close.addEventListener('click', function() {
            document.querySelector('.mob-mod').remove();

            if (document.body.classList.contains('ov_h')) {
                document.body.classList.remove('ov_h');
            }
        });
    }
}

function handleFlickityOnChange(itemNumber) {
    const sectionContainer = document.querySelector('.mob-mod__dropdown-section');
    const imgList = document.querySelectorAll('.mob-mod__carousel-image');
    const currentImage = imgList[itemNumber];
    const toggleContainer = document.querySelector('.mob-mod__dropdown-toggle');
    const currentItem = toggleContainer.querySelector('.mob-mod__dropdown-item');
    const currentItemSectionId = currentItem.dataset.sectionid;
    const currentImageSectionId = currentImage.dataset.sectionid;

    if (currentItemSectionId !== currentImageSectionId) {
        const mobileModal = document.querySelector('.mob-mod');
        const dropdownMenu = mobileModal.querySelector('.dropdown-menu');
        const correctCurrentItem = dropdownMenu.querySelector(`div[data-sectionid=${currentImageSectionId}]`)
        dropdownMenu.appendChild(currentItem);
        toggleContainer.insertBefore(correctCurrentItem, toggleContainer.firstChild);
    }

    sectionContainer.textContent = currentImage.dataset.name;

    setCurrentOpenedImage(currentImage);
}

function handleDropdownItemClick() {
    setCurrentSliderState(this);
}

function setCurrentSliderState(newItem, mobileModal = document.querySelector('.mob-mod')) {
    const newSectionId = newItem.dataset.sectionid;
    const modalSectionName = mobileModal.querySelector('.mob-mod__dropdown-section');
    const imageNodeList = mobileModal.querySelectorAll('.mob-mod__carousel-image');
    const imageList = [...imageNodeList];
    const toggleContainer = document.querySelector('.mob-mod__dropdown-toggle');
    const dropdownMenu = mobileModal.querySelector('.dropdown-menu');
    const currentItem = toggleContainer.querySelector('.mob-mod__dropdown-item');
    const newImageIndex = imageList.findIndex((image) => image.dataset.sectionid === newSectionId);

    modalSectionName.textContent = imageList[newImageIndex].dataset.name;
    toggleContainer.insertBefore(newItem, toggleContainer.firstChild);
    flickitySlide.select(newImageIndex, false, true);

    if (currentItem) {
        dropdownMenu.appendChild(currentItem);
    }
}
import modalJSONData from './data.json';
import {handlebars} from 'hbs';

const CLASS_DATA = {
    activeImg: 'modal__image_active',
    activeNav: 'modal__nav-item_active',
};

const SELECTOR_DATA = {
    activeImg: `.${CLASS_DATA.activeImg}`,
    activeNav: `.${CLASS_DATA.activeNav}`,
};

const modalTemplate = '<div class="modal">\n' +
    '    <svg class="modal__close">\n' +
    '        <use href="#cross" xlink:href="#cross" />\n' +
    '    </svg>\n' +
    '    <div class="modal__content">\n' +
    '        <div class="modal__image-container">\n' +
    '            {{#each sectionList}}\n' +
    '                {{#each linkList}}\n' +
    '                    {{#each imgList}}\n' +
    '                        <img alt="slide" loading="lazy" class="modal__image" data-id="{{id}}" data-sectionId="{{sectionId}}" src="{{src}}">\n' +
    '                    {{/each}}\n' +
    '                {{/each}}\n' +
    '            {{/each}}\n' +
    '            <div class="modal__arrow-nav-container">\n' +
    '                <div class="modal__arrow-nav-backdrop" data-direction="prev">\n' +
    '                    <svg class="modal__arrow-nav" >\n' +
    '                        <use href="#arrow-left" xlink:href="#arrow-left" />\n' +
    '                    </svg>\n' +
    '                </div>\n' +
    '                <div class="modal__arrow-nav-backdrop" data-direction="next">\n' +
    '                    <svg class="modal__arrow-nav">\n' +
    '                        <use href="#arrow-right" xlink:href="#arrow-right" />\n' +
    '                    </svg>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div id="accordion" class="accordion modal__nav">\n' +
    '            {{#each sectionList}}\n' +
    '                <div class="card" data-sectionId="{{sectionId}}">\n' +
    '                        <div class="card-header" id="{{headingId}}">\n' +
    '                          <button class="btn collapsed"\n' +
    '                                  type="button"\n' +
    '                                  data-toggle="collapse"\n' +
    '                                  data-target="#{{collapseId}}"\n' +
    '                                  aria-expanded="false"\n' +
    '                                  aria-controls="{{collapseId}}">\n' +
    '                              {{name}}\n' +
    '                          </button>\n' +
    '                        </div>\n' +
    '                        <div id="{{collapseId}}" class="collapse" aria-labelledby="{{headingId}}" data-parent="#accordion">\n' +
    '                          <div class="card-body">\n' +
    '                            <ul class="modal__link-nav">\n' +
    '                                {{#each linkList}}\n' +
    '                                    <li class="modal__nav-item" data-id="{{id}}">\n' +
    '                                        <svg class="modal__nav-icon">\n' +
    '                                            <use href="#{{icon}}" xlink:href="#{{icon}}" />\n' +
    '                                        </svg>\n' +
    '                                        <span class="modal__nav-text">{{name}}</span>\n' +
    '                                    </li>\n' +
    '                                {{/each}}\n' +
    '                            </ul>\n' +
    '                          </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '            {{/each}}\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>';

function swapActive(active, nextActive, className) {
    active.classList.remove(className);
    nextActive.classList.add(className);
}

function getNextElement(element, direction) {
    if (direction === 'prev') {
        return element.previousElementSibling;
    } else {
        return element.nextElementSibling;
    }
}

function handleArrowNavClick(modal) {
    return function() {
        const direction = this.dataset.direction;
        const activeImg = modal.querySelector(SELECTOR_DATA.activeImg);
        const nextImg = getNextElement(activeImg, direction);

        if (Boolean(nextImg) && nextImg.tagName === 'IMG') {
            const activeNav = modal.querySelector(SELECTOR_DATA.activeNav);
            const activeId = nextImg.dataset.id;
            const activeSectionId = nextImg.dataset.sectionid;
            const currentSectionId = activeImg.dataset.sectionid;

            if (activeSectionId !== currentSectionId) {
                const currentSectionCard = modal.querySelector(`div[data-sectionId=${currentSectionId}]`);
                const activeSectionCard = modal.querySelector(`div[data-sectionId=${activeSectionId}]`);

                closeActiveCard(currentSectionCard);
                openActiveCard(activeSectionCard, direction === 'next');
            } else if (activeNav.dataset.id !== activeId) {
                const nextNav = getNextElement(activeNav, direction);
                swapActive(activeNav, nextNav, CLASS_DATA.activeNav);
            }

            swapActive(activeImg, nextImg, CLASS_DATA.activeImg);
        }
    };
}

function handleNavClick(modal) {
    return function() {
        const id = this.dataset.id;
        const imgNodeList = modal.getElementsByClassName('modal__image');
        const activeImg = modal.querySelector(SELECTOR_DATA.activeImg);
        const activeNav = modal.querySelector(SELECTOR_DATA.activeNav);
        const imgList = [...imgNodeList];
        const img = imgList.find((img) => img.dataset.id === id);

        swapActive(activeImg, img, CLASS_DATA.activeImg);
        swapActive(activeNav, this, CLASS_DATA.activeNav);
    };
}

export function handleOrderClick() {
    const currentModal = document.querySelector('.modal');

    if (!currentModal) {
        const sectionName = this.dataset.name;
        const {sectionList} = modalJSONData;
        const renderFunction = handlebars.compile(modalTemplate);
        const innerHTML = renderFunction({sectionList});
        const temp = document.createElement('div');
        temp.innerHTML = innerHTML;
        const modal = temp.firstElementChild;

        const imgSectionList = temp.querySelectorAll(`img[data-sectionid=${sectionName}]`);
        const sectionCard = temp.querySelector(`div[data-sectionid=${sectionName}]`);
        const close = temp.querySelector('.modal__close');
        const linkList = temp.getElementsByClassName('modal__nav-item');
        const arrowLinkList = temp.getElementsByClassName('modal__arrow-nav-backdrop');

        imgSectionList[0].classList.add(CLASS_DATA.activeImg);
        openActiveCard(sectionCard);

        for (let link of linkList) {
            link.addEventListener('click', handleNavClick(modal));
        }

        for (let arrow of arrowLinkList) {
            arrow.addEventListener('click', handleArrowNavClick(modal));
        }

        document.body.appendChild(modal);

        //Привет, Илюшкинс, про этот костыль я написал в компоненте modal
        if (!document.body.classList.contains('ov_h')) {
            document.body.classList.add('ov_h');
        }

        close.addEventListener('click', function() {
            document.querySelector('.modal').remove();

            //Привет, Илюшкинс, это костыль для айфона, нужно скрывать скролл на мобилке, иначе
            //его пидорасит, я рендерю сразу две модалки (для десктопа и для модалки),
            // поэтому скролл скрывается в любом случае, тут я его удаляю при случае. Почему модалки две а не одна адаптивная?
            // Да потому что они пизздец не похожи, пришлось две делать
            if (document.body.classList.contains('ov_h')) {
                document.body.classList.remove('ov_h');
            }
        });

    }
}

function openActiveCard(card, firstItemActive = true) {
    const collapse = card.querySelector('.collapse');
    const button = card.querySelector('.btn');

    collapse.classList.add('show');
    button.classList.remove('collapsed');

    if (firstItemActive) {
        const navItem = card.querySelector('.modal__nav-item');
        navItem.classList.add(CLASS_DATA.activeNav);
    } else {
        const navItemList = card.querySelectorAll('.modal__nav-item');
        const lastNavItem = navItemList[navItemList.length - 1];
        lastNavItem.classList.add(CLASS_DATA.activeNav);

    }
}

function closeActiveCard(card) {
    const collapse = card.querySelector('.collapse');
    const button = card.querySelector('.btn');
    const activeNavItem = card.querySelector(SELECTOR_DATA.activeNav);
    collapse.classList.remove('show');
    button.classList.add('collapsed');
    activeNavItem.classList.remove(CLASS_DATA.activeNav);
}
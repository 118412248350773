
export function bindImageZoom(carousel, elementToHide = null) {
    if (carousel) {
        const images = carousel.querySelectorAll('img');

        for (let image of images) {
            image.removeEventListener('click', handleClickImage(elementToHide));
            image.addEventListener('click', handleClickImage(elementToHide));
        }
    }
}


function handleClickImage(elementToHide) {
    return function() {
        const container = document.createElement('div');
        container.classList += 'zoomed-image';

        const close = document.createElement('span');
        close.classList += 'zoomed-image__close fa fa-times fa-4x text-white';

        const backdrop = document.createElement('div');
        backdrop.classList += 'zoomed-image__backdrop';

        const image = document.createElement('img');
        image.src = this.src;
        image.classList += 'zoomed-image__image';

        container.appendChild(backdrop);
        container.appendChild(image);
        container.appendChild(close);

        if (elementToHide) {
            elementToHide.classList.toggle('hidden');
        }
        document.querySelector('body').appendChild(container);


        close.addEventListener('click', function() {
            if (elementToHide) {
                elementToHide.classList.toggle('hidden');
            }
            document.querySelector('.zoomed-image').remove();
        });
    }
}


export function setCurrentOpenedImage(currentImage) {
    const zoomedImageContainer = document.getElementsByClassName('zoomed-image')[0];
    if (zoomedImageContainer) {
        const zoomedImage = zoomedImageContainer.getElementsByTagName('img')[0];
        zoomedImage.src = currentImage.src;
    }
}
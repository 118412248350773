import {handleOrderClick} from '../../components/modal/index.js';
import {handleOrderMobileClick} from '../../components/modal-mobile/index.js';

document.addEventListener('DOMContentLoaded', function () {
    initModal();
});


function initModal() {
    const btnNodeList = document.getElementsByClassName('dm__order');

    for (let node of btnNodeList) {
        node.addEventListener('click', handleOrderClick);
        node.addEventListener('click', handleOrderMobileClick);
    }
}